import React from 'react';
import { PanelProps } from '@grafana/data';

import { FactoryOverviewOptions } from './types';
import { OverviewImage } from './components/OverviewImage';

interface Props extends PanelProps<FactoryOverviewOptions> {}

export const FactoryOverviewPanel: React.FC<Props> = ({ options, data, width, height }) => {
  return <OverviewImage options={options}></OverviewImage>;
};
