import React from 'react';
import { css, cx } from 'emotion';

export enum DotState {
  green,
  red,
  white,
}

interface Props {
  dotState: DotState;
  infoTitle: string | undefined;
  infoMessage: string | undefined;
  image?: any | undefined;
}

export const StatusDot = ({ dotState, infoTitle, infoMessage, image }: Props) => {
  const [hovered, setHovered] = React.useState(false);
  // const [showPopover, setShowPopover] = React.useState(false);
  const color = dotState === DotState.red ? '#E7302A' : dotState === DotState.green ? '#69B01B' : 'white';
  return (
    <div>
      <div
        // onClick={() => {
        //   setShowPopover(true);
        // }}
        // onAbort={() => {
        //   setShowPopover(false);
        // }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={cx(
          css`
            transform: scale(${hovered ? 1.2 : 1.0});
          `
        )}
      >
        <svg viewBox="0 0 40 40" width={34} height={34}>
          <circle cx="20" cy="20" r="18" stroke={color} fill="none" strokeWidth={3} />
          <circle cx="20" cy="20" r="8" fill={color} />
        </svg>
      </div>
      {hovered && (
        <div
          className={css`
            background-color: #313131;
            border-radius: 20px;
            background-color: #313131;
            box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
            top: -160px;
            left: 60px;
            width: 300px;
            position: absolute;
            z-index: 1000;
            padding: 2em;
          `}
        >
          {image && (
            <div
              className={cx(
                css`
                  padding: 10%;
                  margin-bottom: 20px;
                  background-color: #e9e8e8;
                `
              )}
            >
              <img
                src={image}
                className={cx(
                  css`
                    width: 100%;
                    object-fit: contain;
                  `
                )}
              ></img>
            </div>
          )}
          <p
            className={css`
              font-size: 1.3rem;
              font-weight: 600;
              text-color: #e7e7e7;
            `}
          >
            {infoTitle ?? 'Bitte Titel eintragen'}
          </p>
          <p
            className={css`
              text-color: #e7e7e7;
            `}
          >
            {infoMessage ?? 'Bitte Beschreibung eintragen'}
          </p>
        </div>
      )}
    </div>
  );
};
