import React, { useState } from 'react';
import { FactoryOverviewOptions } from '../types';
import { css, cx } from 'emotion';
import { Button, stylesFactory } from '@grafana/ui';
import { DotState, StatusDot } from './StatusDot';

const campus_all = require('../img/campus_all.png');
const campus_engerieversorgung = require('../img/campus_energieversorgung.png');
const campus_haustechnik = require('../img/campus_haustechnik.png');
const campus_produktion = require('../img/campus_produktion.png');

const arburg_logo = require('../img/arburg_logo.svg');
const buerkle_logo = require('../img/buerkle_logo.svg');
const homag_logo = require('../img/homag_logo.svg');
const koch_logo = require('../img/koch_logo.png');
const schmalz_logo = require('../img/schmalz_logo.svg');

interface ImageWithDotsOptions {
  options: FactoryOverviewOptions;
}

interface StatusDotConfig {
  x: number;
  y: number;
  title: string;
  description: string;
  dotState: DotState;
}

const getEnergyRoomDots = (options: FactoryOverviewOptions) => {
  let items: StatusDotConfig[] = [];
  if (options.energyroom_statusDotCount > 0) {
    items.push({
      title: options.energyroom_dot1Title,
      description: options.energyroom_dot1Description,
      x: options.energyroom_dot1XPosition,
      y: options.energyroom_dot1YPosition,
      dotState: DotState.white,
    });
  }
  if (options.energyroom_statusDotCount > 1) {
    items.push({
      title: options.energyroom_dot2Title,
      description: options.energyroom_dot2Description,
      x: options.energyroom_dot2XPosition,
      y: options.energyroom_dot2YPosition,
      dotState: DotState.white,
    });
  }
  if (options.energyroom_statusDotCount > 2) {
    items.push({
      title: options.energyroom_dot3Title,
      description: options.energyroom_dot3Description,
      x: options.energyroom_dot3XPosition,
      y: options.energyroom_dot3YPosition,
      dotState: DotState.white,
    });
  }
  console.log(items);
  return items;
};

const getHoustechnicDots = (options: FactoryOverviewOptions) => {
  let items: StatusDotConfig[] = [];
  if (options.housetechnic_statusDotCount > 0) {
    items.push({
      title: options.housetechnic_dot1Title,
      description: options.housetechnic_dot1Description,
      x: options.housetechnic_dot1XPosition,
      y: options.housetechnic_dot1YPosition,
      dotState: DotState.white,
    });
  }
  if (options.housetechnic_statusDotCount > 1) {
    items.push({
      title: options.housetechnic_dot2Title,
      description: options.housetechnic_dot2Description,
      x: options.housetechnic_dot2XPosition,
      y: options.housetechnic_dot2YPosition,
      dotState: DotState.white,
    });
  }
  if (options.housetechnic_statusDotCount > 2) {
    items.push({
      title: options.housetechnic_dot3Title,
      description: options.housetechnic_dot3Description,
      x: options.housetechnic_dot3XPosition,
      y: options.housetechnic_dot3YPosition,
      dotState: DotState.white,
    });
  }
  console.log(items);
  return items;
};

export const OverviewImage: React.FC<ImageWithDotsOptions> = ({ options }) => {
  const styles = getStyles();
  const [image, setImage] = useState<any>(campus_all);
  const [title, setTitle] = useState<string>('Fabrik');

  const setImageAndTitle = (image: any, title: string) => {
    setImage(image);
    setTitle(title);
  };

  return (
    <div>
      <h1
        className={cx(
          css`
            text-align: center;
            margin-top: 2rem;
          `
        )}
      >
        {title}
      </h1>
      <div
        className={cx(
          css`
            position: relative;
            display: flex;
            padding: 8rem;
          `
        )}
      >
        <img
          src={image}
          className={cx(
            css`
              width: 100%;
              object-fit: contain;
            `
          )}
        ></img>

        {campus_produktion !== image && (
          <Button
            size="lg"
            onClick={() => setImageAndTitle(campus_produktion, 'Produktion')}
            className={cx(
              css`
                top: 54%;
                left: 50%;
              `,
              styles.button
            )}
          >
            Produktion
          </Button>
        )}
        {campus_produktion === image && (
          <div>
            <div
              key="ARBURG"
              className={cx(
                css`
                  top: ${options.production_ARBURG_YPosition}%;
                  left: ${options.production_ARBURG_XPosition}%;
                  position: absolute;
                `
              )}
            >
              <StatusDot
                dotState={DotState.white}
                infoMessage={options.production_ARBURG_Description}
                infoTitle={options.production_ARBURG_Title}
                image={arburg_logo}
              />
            </div>
            <div
              key="HOMAG Group"
              className={cx(
                css`
                  top: ${options.production_HOMAG_YPosition}%;
                  left: ${options.production_HOMAG_XPosition}%;
                  position: absolute;
                `
              )}
            >
              <StatusDot
                dotState={DotState.white}
                infoMessage={options.production_HOMAG_Description}
                infoTitle={options.production_HOMAG_Title}
                image={homag_logo}
              />
            </div>
            <div
              key="KOCH Pac Systeme"
              className={cx(
                css`
                  top: ${options.production_KOCHPAC_YPosition}%;
                  left: ${options.production_KOCHPAC_XPosition}%;
                  position: absolute;
                `
              )}
            >
              <StatusDot
                dotState={DotState.white}
                infoMessage={options.production_KOCHPAC_Description}
                infoTitle={options.production_KOCHPAC_Title}
                image={koch_logo}
              />
            </div>
            <div
              key="J. Schmalz"
              className={cx(
                css`
                  top: ${options.production_SCHMALZ_YPosition}%;
                  left: ${options.production_SCHMALZ_XPosition}%;
                  position: absolute;
                `
              )}
            >
              <StatusDot
                dotState={DotState.white}
                infoMessage={options.production_SCHMALZ_Description}
                infoTitle={options.production_SCHMALZ_Title}
                image={schmalz_logo}
              />
            </div>
            <div
              key="Bürkle"
              className={cx(
                css`
                  top: ${options.production_BUERKLE_YPosition}%;
                  left: ${options.production_BUERKLE_XPosition}%;
                  position: absolute;
                `
              )}
            >
              <StatusDot
                dotState={DotState.white}
                infoMessage={options.production_BUERKLE_Description}
                infoTitle={options.production_BUERKLE_Title}
                image={buerkle_logo}
              />
            </div>
          </div>
        )}
        {campus_engerieversorgung !== image && (
          <Button
            size="lg"
            onClick={() => setImageAndTitle(campus_engerieversorgung, 'Energieversorgung')}
            className={cx(
              css`
                top: 20%;
                right: 16%;
              `,
              styles.button
            )}
          >
            Energieversorgung
          </Button>
        )}
        {campus_engerieversorgung === image && (
          <div>
            {getEnergyRoomDots(options).map((dot, index) => (
              <div
                key={`energyroom_dot_${index}`}
                className={cx(
                  css`
                    top: ${dot.y}%;
                    left: ${dot.x}%;
                    position: absolute;
                  `
                )}
              >
                <StatusDot dotState={dot.dotState} infoMessage={dot.description} infoTitle={dot.title} />
              </div>
            ))}
          </div>
        )}
        {campus_haustechnik !== image && (
          <Button
            size="lg"
            onClick={() => setImageAndTitle(campus_haustechnik, 'Haustechnik')}
            className={cx(
              css`
                top: 25%;
                left: 18%;
              `,
              styles.button
            )}
          >
            Haustechnik
          </Button>
        )}
        {campus_haustechnik === image && (
          <div>
            {getHoustechnicDots(options).map((dot, index) => (
              <div
                key={`housetechnic_dot_${index}`}
                className={cx(
                  css`
                    top: ${dot.y}%;
                    left: ${dot.x}%;
                    position: absolute;
                  `
                )}
              >
                <StatusDot dotState={dot.dotState} infoMessage={dot.description} infoTitle={dot.title} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    button: css`
      background: #efefef;
      color: black;
      border-radius: 30px;
      position: absolute;
      &:hover {
        color: black;
        background: #bdbdbd;
      }
    `,
  };
});
