import { PanelPlugin } from '@grafana/data';
import { FactoryOverviewOptions } from './types';
import { FactoryOverviewPanel } from './FactoryOverviewPanel';

const statusDotSettings = {
  defaultValue: 0,
  settings: {
    max: 100,
    min: 0,
    step: 1,
  },
};

export const plugin = new PanelPlugin<FactoryOverviewOptions>(FactoryOverviewPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'title',
      name: 'Fabriktitel',
      description: 'Titel der Fabrik',
      defaultValue: 'Campus Schwarzwald',
    })
    .addFieldNamePicker({
      path: 'production_ARBURG_StatusQueryName',
      name: 'ARBURG (QueryName)',
      description: 'Name der Query',
      category: ['Produktion Punkte'],
    })
    .addSliderInput({
      path: 'production_ARBURG_XPosition',
      name: 'X-Position ARBURG (Prozent)',
      description: 'X-Position ARBURG',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'production_ARBURG_YPosition',
      name: 'Y-Position ARBURG (Prozent)',
      description: 'Y-Position ARBURG',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addTextInput({
      path: 'production_ARBURG_Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: 'ARBURG',
      category: ['Produktion Punkte'],
    })
    .addTextInput({
      path: 'production_ARBURG_Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue:
        'Mit der hybriden Kunststoffspritzgießmaschine ALLROUNDER 370 E 600 – 100 der Firma ARBURG können kunststoffteile in hoher Qualität und Stückzahl mit einer Schließkraft von bis zu 600 Tonnen hergestellt werden.',
      category: ['Produktion Punkte'],
    })
    .addFieldNamePicker({
      path: 'production_KOCHPAC_StatusQueryName',
      name: 'KOCH Pac-Systeme (QueryName)',
      description: 'Name der Query',
      category: ['Produktion Punkte'],
    })
    .addSliderInput({
      path: 'production_KOCHPAC_XPosition',
      name: 'X-Position KOCH Pac-Systeme (Prozent)',
      description: 'X-Position KOCH Pac-Systeme',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'production_KOCHPAC_YPosition',
      name: 'Y-Position KOCH Pac-Systeme (Prozent)',
      description: 'Y-Position KOCH Pac-Systeme',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addTextInput({
      path: 'production_KOCHPAC_Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: 'KOCH Pac-Systeme',
      category: ['Produktion Punkte'],
    })
    .addTextInput({
      path: 'production_KOCHPAC_Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue:
        'Die Blisterverpackungsmaschine KB-KF der Firma KOCH Pac-Systeme GmbH produziert Blisterverpackungen, die beispielweise im Bereich der Medizintechnik (Verpackung für Spritzen, Kontaktlinsen), Verpackung von Konsumgütern (Batterien, Zahnbürsten) Anwendung finden. Als Rohmaterial wird Kunststofffolie über Heizplatten erhitzt, in ein Formwerkzeug transportiert und dort geklemmt, sowie durch Vakuum an der unteren Formseite und Druckluft an der oberen Formseite in die Blistergeometrie gebracht.',
      category: ['Produktion Punkte'],
    })
    .addFieldNamePicker({
      path: 'production_SCHMALZ_StatusQueryName',
      name: 'J.Schmalz (QueryName)',
      description: 'Name der Query',
      category: ['Produktion Punkte'],
    })
    .addSliderInput({
      path: 'production_SCHMALZ_XPosition',
      name: 'X-Position J.Schmalz (Prozent)',
      description: 'X-Position J.Schmalz',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'production_SCHMALZ_YPosition',
      name: 'Y-Position J.Schmalz (Prozent)',
      description: 'Y-Position J.Schmalz',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addTextInput({
      path: 'production_SCHMALZ_Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: 'J.Schmalz',
      category: ['Produktion Punkte'],
    })
    .addTextInput({
      path: 'production_SCHMALZ_Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue:
        'Durch die Verknüpfung von intelligenten Vakuumgreifern der Firma J. Schmalt GmbH an kollaborativen Robotern wird die Zusammenarbeit zwischen Menschen und Maschine ermöglicht. Einsatzfelder sind z.B. Einlegen und Entnahme von Werkstücken.',
      category: ['Produktion Punkte'],
    })
    .addFieldNamePicker({
      path: 'production_HOMAG_StatusQueryName',
      name: 'Wert HOMAG Group(QueryName)',
      description: 'Name der Query',
      category: ['Produktion Punkte'],
    })
    .addSliderInput({
      path: 'production_HOMAG_XPosition',
      name: 'X-Position HOMAG Group (Prozent)',
      description: 'X-Position HOMAG Group',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'production_HOMAG_YPosition',
      name: 'Y-Position HOMAG Group (Prozent)',
      description: 'Y-Position HOMAG Group',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addTextInput({
      path: 'production_HOMAG_Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: 'HOMAG Group',
      category: ['Produktion Punkte'],
    })
    .addTextInput({
      path: 'production_HOMAG_Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue:
        'Mit dem 5-Achs-CNC-Holzbearbeitungszentrum BMG611 der Firma HOMAG können vielfältige Aufgabenstellungen im Bereich der Fertigung von Möbeln, Innenausbau oder Bauelementen bewältigt werden. Vom Fräsen, Bohren über das Sägen von Holz wird eine große Bandbreite an Bearbeitungsschritten auf einer Maschine realisiert. Eine Absauganlage zur Aufnahme der Holzspäne und -Partikel komplettiert die Anlage.',
      category: ['Produktion Punkte'],
    })
    .addFieldNamePicker({
      path: 'production_BUERKLE_StatusQueryName',
      name: 'Wert Robert Bürkle (QueryName)',
      description: 'Name der Query',
      category: ['Produktion Punkte'],
    })
    .addSliderInput({
      path: 'production_BUERKLE_XPosition',
      name: 'X-Position Robert Bürkle (Prozent)',
      description: 'X-Position Robert Bürkle',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'production_BUERKLE_YPosition',
      name: 'Y-Position Robert Bürkle (Prozent)',
      description: 'Y-Position Robert Bürkle',
      category: ['Produktion Punkte'],
      ...statusDotSettings,
    })
    .addTextInput({
      path: 'production_BUERKLE_Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: 'Robert Bürkle',
      category: ['Produktion Punkte'],
    })
    .addTextInput({
      path: 'production_BUERKLE_Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue:
        'Mit der Multilayer-Pressanlage WORKCELL² der Firma Robert Bürkle GmbH werden Mehrschicht-Leiterplatten in der Elektronikindustrie laminiert. Innerhalb der Anlage werden beladene Werkzeuge unter Vakuum aufgeheizt und verpresst, sowie unter Druck wieder abgekühlt. Die Produkte (Chips) finden weite Verbreitung in Computern und mobilen Endgeräten.',
      category: ['Produktion Punkte'],
    })
    .addNumberInput({
      path: 'housetechnic_statusDotCount',
      name: 'Anzahl Status-Punkte Haustechnik',
      defaultValue: 0,
      category: ['Haustechnik Punkte'],
      settings: {
        integer: true,
        min: 0,
        max: 3,
        step: 1,
      },
    })
    .addFieldNamePicker({
      path: 'housetechnic_dot1StatusQueryName',
      name: 'Wert Punkt 1 (QueryName)',
      description: 'Name der Query',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 0,
    })
    .addSliderInput({
      path: 'housetechnic_dot1XPosition',
      name: 'X-Position Punkt 1 (Prozent)',
      description: 'X-Position Punkt 1',
      category: ['Haustechnik Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 0,
    })
    .addSliderInput({
      path: 'housetechnic_dot1YPosition',
      name: 'Y-Position Punkt 1 (Prozent)',
      description: 'Y-Position Punkt 1',
      category: ['Haustechnik Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 0,
    })
    .addTextInput({
      path: 'housetechnic_dot1Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 0,
    })
    .addTextInput({
      path: 'housetechnic_dot1Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 0,
    })
    .addFieldNamePicker({
      path: 'housetechnic_dot2StatusQueryName',
      name: 'Wert Punkt 2 (QueryName)',
      description: 'Name der Query',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 1,
    })
    .addSliderInput({
      path: 'housetechnic_dot2XPosition',
      name: 'X-Position Punkt 2 (Prozent)',
      description: 'X-Position Punkt 2',
      category: ['Haustechnik Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 1,
    })
    .addSliderInput({
      path: 'housetechnic_dot2YPosition',
      name: 'Y-Position Punkt 2 (Prozent)',
      description: 'Y-Position Punkt 2',
      category: ['Haustechnik Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 1,
    })
    .addTextInput({
      path: 'housetechnic_dot2Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 1,
    })
    .addTextInput({
      path: 'housetechnic_dot2Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 1,
    })
    .addFieldNamePicker({
      path: 'housetechnic_dot3StatusQueryName',
      name: 'Wert Punkt 3 (QueryName)',
      description: 'Name der Query',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 2,
    })
    .addSliderInput({
      path: 'housetechnic_dot3XPosition',
      name: 'X-Position Punkt 3 (Prozent)',
      description: 'X-Position Punkt 3',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 2,
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'housetechnic_dot3YPosition',
      name: 'Y-Position Punkt 3 (Prozent)',
      description: 'Y-Position Punkt 3',
      category: ['Haustechnik Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 2,
    })
    .addTextInput({
      path: 'dot3Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 2,
    })
    .addTextInput({
      path: 'housetechnic_dot3Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Haustechnik Punkte'],
      showIf: (currentOptions, data) => currentOptions.housetechnic_statusDotCount > 2,
    })
    .addNumberInput({
      path: 'energyroom_statusDotCount',
      name: 'Anzahl Status-Punkte Energiversorgung',
      defaultValue: 0,
      category: ['Energiversorgung Punkte'],
      settings: {
        integer: true,
        min: 0,
        max: 3,
        step: 1,
      },
    })
    .addFieldNamePicker({
      path: 'energyroom_dot1StatusQueryName',
      name: 'Wert Punkt 1 (QueryName)',
      description: 'Name der Query',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 0,
    })
    .addSliderInput({
      path: 'energyroom_dot1XPosition',
      name: 'X-Position Punkt 1 (Prozent)',
      description: 'X-Position Punkt 1',
      category: ['Energiversorgung Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 0,
    })
    .addSliderInput({
      path: 'energyroom_dot1YPosition',
      name: 'Y-Position Punkt 1 (Prozent)',
      description: 'Y-Position Punkt 1',
      category: ['Energiversorgung Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 0,
    })
    .addTextInput({
      path: 'energyroom_dot1Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 0,
    })
    .addTextInput({
      path: 'energyroom_dot1Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 0,
    })
    .addFieldNamePicker({
      path: 'energyroom_dot2StatusQueryName',
      name: 'Wert Punkt 2 (QueryName)',
      description: 'Name der Query',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 1,
    })
    .addSliderInput({
      path: 'energyroom_dot2XPosition',
      name: 'X-Position Punkt 2 (Prozent)',
      description: 'X-Position Punkt 2',
      category: ['Energiversorgung Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 1,
    })
    .addSliderInput({
      path: 'energyroom_dot2YPosition',
      name: 'Y-Position Punkt 2 (Prozent)',
      description: 'Y-Position Punkt 2',
      category: ['Energiversorgung Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 1,
    })
    .addTextInput({
      path: 'energyroom_dot2Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 1,
    })
    .addTextInput({
      path: 'energyroom_dot2Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 1,
    })
    .addFieldNamePicker({
      path: 'energyroom_dot3StatusQueryName',
      name: 'Wert Punkt 3 (QueryName)',
      description: 'Name der Query',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 2,
    })
    .addSliderInput({
      path: 'energyroom_dot3XPosition',
      name: 'X-Position Punkt 3 (Prozent)',
      description: 'X-Position Punkt 3',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 2,
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'energyroom_dot3YPosition',
      name: 'Y-Position Punkt 3 (Prozent)',
      description: 'Y-Position Punkt 3',
      category: ['Energiversorgung Punkte'],
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 2,
    })
    .addTextInput({
      path: 'energyroom_dot3Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 2,
    })
    .addTextInput({
      path: 'energyroom_dot3Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Energiversorgung Punkte'],
      showIf: (currentOptions, data) => currentOptions.energyroom_statusDotCount > 2,
    });
});
